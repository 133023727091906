<template>
  <div v-if="ready" :key="refreshKey" id="app">
    <router-view :key="refreshKey" />
  </div>
</template>

<script>
import { emit as socketEmit } from '@/utils/socket'

export default {
  name: 'app',
  data() {
    return {
      ready: false,
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    refreshKey() {
      return this.$store.state.appRefreshKey
    },
    toasts() {
      return this.$store.state.toasts
    },
    pathName() {
      return this.$route.name
    },
  },
  created() {
    this.auth()
  },
  methods: {
    async auth() {
      try {
        await this.$store.dispatch('Auth')
      } catch {}
      if (!this.user.isLoggedIn) {
        this.$store.dispatch('login')
      } else if (this.user.isLoggedIn && !this.user.endpointId) {
        this.$router.push({ name: 'Settings' })
        this.toast('MDM недоступен\nПроверьте реквизиты')
      } else if (!this.user.access) {
        if (
          confirm(
            'У пользователя отсутствует доступ к данному приложению. \nОбратитесь к администратору. \nАвторизоваться в другой учетной записи?',
          )
        ) {
          this.$store.dispatch('Logout')
        }
      } else if (this.user.access) {
        await this.redirectEndpoint()
        await this.redirectRule()
        this.ready = true
        this.$store.dispatch('GetLicense')
      }
    },
    async redirectEndpoint() {
      const params = new URLSearchParams(window.location.search)
      const endpoint = params.get('endpoint')
      if (endpoint) {
        params.delete('endpoint')
        window.location.search = '?' + params.toString()
        await this.$store.dispatch('SetEndpoint', endpoint)
      }
    },
    async redirectRule() {
      const params = new URLSearchParams(window.location.search)
      const rule = params.get('rule')
      if (rule) {
        sessionStorage.setItem('openRule', rule)
        this.$router.push({ path: '/rules' })
      }
    },
  },
  watch: {
    refreshKey(val, oldVal) {
      if (val != oldVal) {
        if (window.location.href.includes('redirect'))
          this.$store.dispatch('LoadClasses')
        else if (oldVal + 2 !== val) window.location.reload()
      }
    },
    toasts: {
      handler(v) {
        if (v.length) {
          for (const [text, type] of v) {
            this.toast(text, type)
          }
          this.$store.commit('clearToasts')
        }
      },
      deep: true,
    },
    pathName: {
      handler(v) {
        socketEmit('path', v)
        const nonScrollPages = ['Rules']
        document.querySelector('html').style.overflow = nonScrollPages.includes(
          v,
        )
          ? 'hidden'
          : 'visible'
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/styles/variables.scss';
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/fonts.scss';
@import '@/assets/styles/toastification.scss';

html {
  font-size: $font-size;
  overflow-x: hidden;
}

body {
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $color-text;
  // min-width: 1600px;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: $color-red-dark;
  @include transition();

  &:hover {
    color: $color-red-bright;
  }
}

p {
  margin: 0;
}

* {
  scrollbar-color: $color-scroll transparent;
  scrollbar-width: thin;
  -webkit-overflow-scrolling: auto;

  &::-webkit-scrollbar {
    width: rem(8px);
  }

  &::-webkit-scrollbar-thumb {
    border-right: 4px solid rgba(0, 0, 0, 0);
    background-clip: content-box;
    background-color: $color-scroll;
  }

  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}

.v-title .v-title-contents {
  word-break: normal !important;
  text-align: center;
  padding: 5px 5px 8px 5px !important;
}
</style>
